import React from "react";
import styled from "styled-components";
import LeftRanking from "./LeftRanking";
import RightScore from "./RightScore";
import CenterName from "./CenterName";
import ListDefs from "./ListDefs";

const ListItem = ({ name, score, visible, index, team, idx, emptyNode }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 116">
      <ListDefs />
      <LeftRanking
        pose={visible ? "visible" : "hidden"}
        index={index}
        key={`left-${idx}`}
        idx={idx}
        emptyNode={emptyNode}
      />
      <RightScore
        score={score}
        pose={visible ? "visible" : "hidden"}
        key={`right-${idx}`}
        index={index}
        idx={idx}
        emptyNode={emptyNode}
      />
      <CenterName
        name={name}
        pose={visible ? "visible" : "hidden"}
        key={`center-${idx}`}
        index={index}
        team={team}
        idx={idx}
        emptyNode={emptyNode}
      />
    </svg>
  );
};

export default ListItem;
