import React, { Component } from "react";
import ListItem from "./ListItem/ListItem";
import { firestore } from "../../config/firebase";

const emptyConsultant = {
  consultant: "null",
  team: "null",
  Ratio: "0"
};

const array = new Array(10);
array.fill(emptyConsultant);

export default class List extends Component {
  state = {
    consultants: [],
    visbleConsultants: [array],
    step: 10,
    visible: false,
    intervalId: null,
    rankingObj: {}
  };

  async componentDidMount() {
    let query;
    let teamParam = this.props.team;

    if (
      teamParam &&
      (teamParam == "armageddon" ||
        teamParam == "accelerate" ||
        teamParam == "carnage" ||
        teamParam == "madamps")
    ) {
      query = firestore
        .collection("consultants")
        .where("team", "array-contains", this.props.team)
        .orderBy(teamParam, "desc");
    } else {
      query = firestore.collection("consultants").orderBy(teamParam, "desc");
    }

    const cons = await query
      .get()
      .then(snap => snap.docs.map(doc => ({ id: doc.id, ...doc.data() })));

    let obj = {};

    cons.map((item, i) => (obj[item.id] = i));

    this.setState({ consultants: cons, rankingObj: obj });

    this.refreshList();
    const interval = setInterval(this.refreshList, 30000);
    // const interval = setInterval(this.refreshList, 15000);
    this.setState({ intervalId: interval });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  refreshList = () => {
    let stepValue, visbleConsultants;
    const { consultants, step } = this.state;
    this.setState({ visible: false }, () => {
      setTimeout(() => {
        if (consultants.length < 11) {
          visbleConsultants = consultants;
          stepValue = 10;
        } else if (step < consultants.length) {
          visbleConsultants = consultants.slice(step - 10, step);
          stepValue = step + 10;
        } else {
          const requiredEmptyNodes =
            Math.ceil(consultants.length / 10) * 10 - consultants.length;
          const arr = new Array(requiredEmptyNodes);
          arr.fill(emptyConsultant);
          visbleConsultants = consultants.slice(step - 10, consultants.length);
          visbleConsultants = visbleConsultants.concat(arr);
          stepValue = 10;
        }
        this.setState({
          step: stepValue,
          visbleConsultants,
          visible: true
        });
      }, 1500);
      // }, 7500);
    });
  };

  getInitials = string => {
    var initials = string.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();
    return initials;
  };

  // fetchConsultant = () => {
  //   return firestore
  //     .collection("consultants")
  //     .orderBy("Ratio", "desc")
  //     .get()
  //     .then(snap => {
  //       snap.docs.map(doc => doc.data());
  //     });
  // };
  render() {
    return (
      <>
        {this.state.visbleConsultants &&
          this.state.visbleConsultants.length != 0 &&
          this.state.visbleConsultants.map((consultant, i) => (
            <ListItem
              key={i}
              idx={i}
              index={this.state.rankingObj[consultant.id]}
              name={consultant.consultant}
              score={consultant[this.props.team]}
              visible={this.state.visible}
              emptyNode={consultant.consultant == "null"}
            />
          ))}
      </>
    );
  }
}
