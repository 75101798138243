import React from "react";
import Header from "./Header/Header";
import AccHeader from "./Accelerate/Header";
import ArmHeader from "./Armageddon/Header";
import CarHeader from "./Carnage/Header";
import MadHeader from "./Madamps/Header";
import List from "./List";
import styled from "styled-components";
import { createGlobalStyle, css } from "styled-components";
import hemi_head_bd_it_eot from "../../../fonts/hemi_head_bd_it-webfont.eot";
import hemi_head_bd_it_ttf from "../../../fonts/hemi_head_bd_it-webfont.ttf";
import hemi_head_bd_it_woff from "../../../fonts/hemi_head_bd_it-webfont.woff";
import hemi_head_bd_it_woff2 from "../../../fonts/hemi_head_bd_it-webfont.woff2";
import hemi_head_bd_it_webfont from "../../../fonts/hemi_head_bd_it-webfont.svg";
import background from "../../../background.jpg";
import Win from "../../../../src/Win.png";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'hemi';
  src: url(${props => props.HemiEot});
  src: url(${props => props.HemiEot}?#iefix) format('embedded-opentype'),
       url(${props => props.HemitWoff2}) format('woff2'),
       url(${props => props.HemitWoff}) format('woff'),
       url(${props => props.HemitTtf}) format('truetype'),
       url(${props => props.HemitSVG}#hemi_headbold_italic) format('svg');
  font-weight: normal;
  font-style: normal;
}
`;

const Container = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-color: #00000050;
  background-blend-mode: multiply;
  height: 100vh;
  position: relative;
`;

const PrizeBlock = styled.div`
  img {
    max-width: 100%;
  }
  @media only screen and (width: 1080px) and (height: 1920px) {
    /* display: block; */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

// const PrizeBlockLandscape = styled.div`
//   display: block;
//   @media only screen and (max-width: 1080px) {
//     display: none;
//   }
// `;

export default function LeaderBoard({ team }) {
  let Head = Header;
  switch (team) {
    case "armageddon":
      Head = ArmHeader;
      break;
    case "accelerate":
      Head = AccHeader;
      break;
    case "carnage":
      Head = CarHeader;
      break;
    case "madamps":
      Head = MadHeader;
      break;
    default:
      Head = Header;
  }
  return (
    <Container>
      <GlobalStyle
        HemiEot={hemi_head_bd_it_eot}
        HemitTtf={hemi_head_bd_it_ttf}
        HemitWoff={hemi_head_bd_it_woff}
        HemitWoff2={hemi_head_bd_it_woff2}
        HemitSVG={hemi_head_bd_it_webfont}
      />
      <Head />
      <List team={team} />
      <PrizeBlock>
        <img src={Win} alt="car" />
      </PrizeBlock>
      {/* <PrizeBlockLandscape>
        <img src={Win} alt="car" />
      </PrizeBlockLandscape> */}
    </Container>
  );
}
