import React from "react";
import styled from "styled-components";
import background from "./background.jpg";
import acc from "./acc.png";
import arm from "./arm.png";
import mad from "./mad.png";
import { Link } from "@reach/router";

const Landing = () => {
  return (
    <Container>
      <Link to="/filter/accelerate">
        <Img src={acc} />
      </Link>
      <Link to="/filter/armageddon">
        <Img src={arm} />
      </Link>
      <Link to="/filter/madamps">
        <Img src={mad} />
      </Link>
    </Container>
  );
};

export default Landing;

const Container = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-color: #00000050;
  background-blend-mode: multiply;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: auto;
  height: 100px;
  transition: 0.2s all ease-in-out;
  margin: 20px;
  &:hover {
    transform: scale(1.1);
  }
`;
