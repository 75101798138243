import React from "react";
import posed from "react-pose";
import styled from "styled-components";

const PosedContainer = posed.g({
  visible: {
    x: 0,
    delay: ({ idx }) => 200 * idx,
    transition: {
      ease: "easeInOut"
    }
  },
  hidden: {
    x: -200,
    delay: ({ idx }) => 100 * idx,
    transition: {
      duration: 200,
      ease: "easeOut"
    }
  }
});

const StyledPosedContainer = styled(PosedContainer)`
  visibility: ${({ emptyNode }) => (emptyNode ? "hidden" : "visible")};
`;

const LeftRanking = ({ pose, index, idx, emptyNode }) => {
  return (
    <StyledPosedContainer
      index={index}
      pose={pose}
      idx={idx}
      emptyNode={emptyNode}
    >
      <path d="M111.58 148.21z" />
      <path fill="#f39200" d="M0 84.09h150.79v15.85H0z" />
      <path fill="#3c2415" d="M0 11.04h150.79v78.7H0z" />
      <text
        transform="translate(23.14 73.67)"
        fontSize="71.9"
        fill="#fff"
        fontFamily="hemi, Hemi Head"
        fontWeight="700"
        fontStyle="italic"
      >
        {index + 1}
      </text>
      <path fill="url(#a)" d="M0 11.04h150.79v88.58H0z" />
    </StyledPosedContainer>
  );
};

export default LeftRanking;
