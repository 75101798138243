import React, { useState } from "react";
import ConsultantService from "./features/consultants/ConsultantService";
import styled from "styled-components";

export function NewConsultantRow({ handleNew }) {
  const [edit, setEdit] = useState(false);
  const [team, setTeam] = useState([]);
  const [consultant, setConsultant] = useState("");
  const [armageddon, setArmageddon] = useState(false);
  const [accelerate, setAccelerate] = useState(false);
  const [madamps, setMadAmps] = useState(false);
  const [armScore, setArmScore] = useState(0);
  const [accScore, setAccScore] = useState(0);
  const [madScore, setMadScore] = useState(0);

  const onSave = async () => {
    let teamArray = [];
    if (armageddon === true) {
      teamArray.push("armageddon");
    }
    if (accelerate === true) {
      teamArray.push("accelerate");
    }
    if (madamps === true) {
      teamArray.push("madamps");
    }

    let scores = {
      accelerate: accScore,
      armageddon: armScore,
      madamps: madScore
    };

    setTeam(teamArray);

    const id = await ConsultantService.addConsultant(
      consultant,
      teamArray,
      scores
    );
    clearForm();
    handleNew({ consultant, scores, team: teamArray, id });
    // window.location.reload();
  };

  const clearForm = () => {
    setTeam([]);
    setConsultant("");
    setArmageddon(false);
    setAccelerate(false);
    setMadAmps(false);
    setAccScore(0);
    setArmScore(0);
    setMadScore(0);
  };

  return (
    <tr onDoubleClick={() => setEdit(true)}>
      <>
        <td>
          <input
            type="text"
            value={consultant}
            placeholder="Add New Consultant"
            onChange={event => {
              setConsultant(event.target.value);
            }}
          />
        </td>

        <td>
          <CheckContainer>
            <Checkbox
              name="accelerate"
              stateValue={accelerate}
              update={setAccelerate}
              label="Accelerate"
            />
            <Checkbox
              name="armageddon"
              stateValue={armageddon}
              update={setArmageddon}
              label="Armageddon"
            />

            <Checkbox
              name="madamps"
              stateValue={madamps}
              update={setMadAmps}
              label="Mad Amps"
            />
          </CheckContainer>
        </td>
        <td>
          <ScoreInput
            label="Accelerate"
            visible={accelerate}
            update={setAccScore}
            score={accScore}
          />
          <ScoreInput
            label="Armageddon"
            visible={armageddon}
            update={setArmScore}
            score={armScore}
          />

          <ScoreInput
            label="Mad Amps"
            visible={madamps}
            update={setMadScore}
            score={madScore}
          />
        </td>
        <td>
          <NewButton
            onClick={() => {
              //   console.log("new consultant");
              onSave();
            }}
          >
            Add New Consultant
          </NewButton>

          {/* <button>del</button> */}
        </td>
      </>
    </tr>
  );
}

const Checkbox = ({ name, label, stateValue, update }) => (
  <label>
    <input
      value={name}
      type="checkbox"
      name={name}
      id={name}
      checked={stateValue}
      onChange={() => {
        update(!stateValue);
      }}
    />
    {label}
  </label>
);

const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;

  input {
    /* padding-right: 10px; */
    margin: 5px;
    box-shadow: none;
  }
`;

const NewButton = styled.button`
  background: #81d281;
  padding: 30px 0;
`;

const ScoreInput = ({ visible, score, update, label }) =>
  visible && (
    <ScoreLabel>
      <input
        type="number"
        value={score}
        onChange={event => {
          update(event.target.value);
        }}
      />
      {label}
    </ScoreLabel>
  );

const ScoreLabel = styled.label`
  display: block;
  padding: 3px;
  border-bottom: 1px solid #80808061;
  input {
    padding: 5px;
    width: 80px;
    box-shadow: none;
  }
`;
