import React, { Component } from "react";
import "./app/config/firebase";
import LeaderBoard from "./app/ui/LeaderBoard/LeaderBoard";
import Importer from "./Importer";
import { Router, Link } from "@reach/router";
import EditConsultants from "./EditConsultants";
import Landing from "./Landing";

class App extends Component {
  render() {
    return (
      <Router>
        <Landing path="/" />
        <LeaderBoard path="/filter/:team" />
        <EditConsultants path="/admin" />
      </Router>
    );
    // return <Importer />;
  }
}

export default App;
