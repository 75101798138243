import React from "react";
import posed from "react-pose";
import styled from "styled-components";

const PosedContainer = posed.g({
  visible: {
    x: 0,
    delay: ({ idx }) => 200 * idx + 100,
    transition: {
      ease: "easeInOut"
    }
  },
  hidden: {
    x: 1000,
    delay: ({ idx }) => 100 * idx,
    transition: {
      duration: 200,
      ease: "easeOut"
    }
  }
});

const StyledPosedContainer = styled(PosedContainer)`
  visibility: ${({ emptyNode }) => (emptyNode ? "hidden" : "visible")};
`;

const RightScore = ({ score, pose, idx, emptyNode }) => {
  let fontSize = "54.74";
  // fontSize = name !== undefined && name.length > 16 ? "50" : "54.74";

  if (score && score.length < 4) {
    fontSize = "74.12";
  } else if (score && score.length < 5) {
    fontSize = "55";
  }
  //  else {
  //   fontSize = "45";
  // }

  return (
    <StyledPosedContainer pose={pose} idx={idx} emptyNode={emptyNode}>
      <path fill="#3c2415" d="M1080 107.47H150.79l-44.15-34.36H1080v34.36z" />
      <path
        fill="#3c2415"
        d="M1080 11.04H238.2L106.64 73.25 1080 89.08V11.04z"
      />
      <text
        transform="translate(887.82 82.86)"
        fontSize={fontSize}
        fill="#fff"
        fontFamily="hemi, Hemi Head"
        fontWeight="700"
        fontStyle="italic"
      >
        {score}%
      </text>
      <path fill="url(#a)" d="M838 11.19h242v78.7H838z" />
    </StyledPosedContainer>
  );
};

export default RightScore;
