import React, { Component } from "react";
import ConsultantService from "./features/consultants/ConsultantService";
import styled from "styled-components";
import { ConsultantRow } from "./ConsultantRow";
import { NewConsultantRow } from "./NewConsultantRow";

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default class EditConsultants extends Component {
  state = { consultants: [] };

  async componentDidMount() {
    const consultants = await ConsultantService.getConsultants();
    this.setState({ consultants });
  }

  handleDelete = id => {
    const consultants = this.state.consultants.filter(
      consultant => consultant.id != id
    );
    this.setState({ consultants });
  };

  onNewConsultant = consultant => {
    let old = this.state.consultants;
    old.push(consultant);
    this.setState({ consultants: old });
  };

  render() {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Team</th>
              <th>Score</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.consultants.length != 0 &&
              this.state.consultants.map(consultant => (
                <ConsultantRow
                  initialName={consultant.consultant}
                  initialTeam={consultant.team}
                  initialScore={consultant.Ratio}
                  initialScores={consultant.scores || {}}
                  id={consultant.id}
                  key={consultant.id}
                  handleDelete={this.handleDelete}
                />
              ))}
            <NewConsultantRow handleNew={this.onNewConsultant} />
          </tbody>
        </table>
      </div>
    );
  }
}
