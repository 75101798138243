import React, { Component } from "react";
import { firestore } from "./app/config/firebase";

const consultants = [
  {
    team: "G",
    consultant: "Shanene Beyers",
    Budget: "660925",
    Actuals: "1336664.77",
    Ratio: "0"
  },
  {
    team: "ST",
    consultant: "Dirk Prinsloo",
    Budget: "96454",
    Actuals: "161289.57",
    Ratio: "0"
  },
  {
    team: "DF",
    consultant: "Keagan Robinson",
    Budget: "675458",
    Actuals: "1095070.57",
    Ratio: "0"
  },
  {
    team: "G",
    consultant: "Byron Labuschagne",
    Budget: "981688",
    Actuals: "1430166.3",
    Ratio: "0"
  },
  {
    team: "ST",
    consultant: "Pieter Du Tiot",
    Budget: "1072987",
    Actuals: "1508342.55",
    Ratio: "0"
  },
  {
    team: "DF",
    consultant: "Jack Haskins",
    Budget: "852958",
    Actuals: "1188655.34",
    Ratio: "0"
  },
  {
    team: "DF",
    consultant: "Ryan Mash",
    Budget: "850774",
    Actuals: "1177492.43",
    Ratio: "0"
  },
  {
    team: "G",
    consultant: "Jacques Smit",
    Budget: "866046",
    Actuals: "1164514.27",
    Ratio: "0"
  },
  {
    team: "ST",
    consultant: "Uluande Marais",
    Budget: "1064700",
    Actuals: "1290822.65",
    Ratio: "0"
  },
  {
    team: "L",
    consultant: "Craig Allchurch",
    Budget: "1130724",
    Actuals: "1366921.1",
    Ratio: "0"
  },
  {
    team: "BC",
    consultant: "Morne Van Der Merwe",
    Budget: "1371426",
    Actuals: "1646326.46",
    Ratio: "0"
  },
  {
    team: "G",
    consultant: "KG Manyelo",
    Budget: "538684",
    Actuals: "638077.21",
    Ratio: "0"
  },
  {
    team: "BC",
    consultant: "Werner Griesel",
    Budget: "895571",
    Actuals: "1057403.44",
    Ratio: "0"
  },
  {
    team: "DF",
    consultant: "Mark Sivell",
    Budget: "1419763",
    Actuals: "1565625",
    Ratio: "0"
  },
  {
    team: "BC",
    consultant: "Donald Froneman",
    Budget: "579407",
    Actuals: "633171.34",
    Ratio: "0"
  },
  {
    team: "BC",
    consultant: "Morne Odendaal",
    Budget: "1172730",
    Actuals: "1237305.45",
    Ratio: "0"
  },
  {
    team: "L",
    consultant: "Christene van Niekerk",
    Budget: "1271702",
    Actuals: "1338390.12",
    Ratio: "0"
  },
  {
    team: "BC",
    consultant: "Johan Swanepoel",
    Budget: "929794",
    Actuals: "950876.46",
    Ratio: "0"
  },
  {
    team: "G",
    consultant: "Brandon LeRoux",
    Budget: "859368",
    Actuals: "865823.33",
    Ratio: "0"
  },
  {
    team: "ST",
    consultant: "LeRoux van Aarde",
    Budget: "343387",
    Actuals: "339269.96",
    Ratio: "0"
  },
  {
    team: "G",
    consultant: "Lionel Kraayeveld",
    Budget: "823347",
    Actuals: "757807.48",
    Ratio: "0"
  },
  {
    team: "G",
    consultant: "Rudi Rademeyer",
    Budget: "201977",
    Actuals: "179746.33",
    Ratio: "0"
  },
  {
    team: "G",
    consultant: "Ricardo Louw",
    Budget: "876912",
    Actuals: "713627.39",
    Ratio: "0"
  },
  {
    team: "L",
    consultant: "Anke Dedekind",
    Budget: "362178",
    Actuals: "276344.08",
    Ratio: "0"
  },
  {
    team: "G",
    consultant: "Hilda Van Der Bank",
    Budget: "2014521",
    Actuals: "1461306.56",
    Ratio: "0"
  },
  {
    team: "ST",
    consultant: "Alastair Laurence",
    Budget: "633720",
    Actuals: "347272.24",
    Ratio: "0"
  },
  {
    team: "ST",
    consultant: "Nick da Silva",
    Budget: "409878",
    Actuals: "193457.48",
    Ratio: "0"
  },
  {
    team: "ST",
    consultant: "Nathan Johnston",
    Budget: "437516",
    Actuals: "202526.34",
    Ratio: "0"
  }
];

export default class Importer extends Component {
  componentDidMount() {
    consultants.forEach(consultant => {
      firestore
        .collection("consultants")
        .add(consultant)
        .then(docRef => {
          console.log("added: ", docRef.id);
        })
        .catch(error => {
          console.log("error: ", error);
        });
    });
  }
  render() {
    return <div />;
  }
}
