import React from "react";
import { firestore } from "../../app/config/firebase";

export default class ConsultantService {
  // Add consultant to firestore
  // on success => returns doc ID
  // on failure +> returns false
  static addConsultant = async (consultant, teams, scores) => {
    return await firestore
      .collection("consultants")
      .add({
        consultant,
        team: teams,
        scores,
        accelerate: parseInt(scores.accelerate),
        armageddon: parseInt(scores.armageddon),
        madamps: parseInt(scores.madamps)
      })
      .then(docRef => {
        console.log("successfully created new consultant: ", docRef.id);
        return docRef.id;
      })
      .catch(error => {
        console.error("Error in Consultant Service: ", error);
        return false;
      });
  };

  // get Consultant
  // on success => return object with doc id and consultant info
  // on failure => return false
  static getConsultant = async id => {
    const docRef = firestore.collection("consultants").doc(id);
    return await docRef
      .get()
      .then(snap => ({ id: snap.id, ...snap.data() }))
      .catch(error => {
        console.error("Error in ConsultantService -> getConsultant: ", error);
        return false;
      });
  };

  static getConsultants = async () => {
    const snapshot = await firestore
      .collection("consultants")
      .orderBy("consultant")
      .get();
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  static updateConsultant = async (id, updatedValuesObj) => {
    var docRef = firestore.collection("consultants").doc(id);
    docRef.update(updatedValuesObj).then(() => {
      console.log("success");
    });
  };

  static deleteConsultant = async id => {
    var docRef = firestore.collection("consultants").doc(id);
    docRef.delete().then(() => {
      console.log("deleted");
    });
  };

  static getConsultantsByTeam = teamID => {};

  static getConsultantsByScore = () => {};
}
