import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyA8lubF8ecK6MRUlsWRe8ja5WoAQvfXLcY",
  authDomain: "zimmer-leaderboard.firebaseapp.com",
  databaseURL: "https://zimmer-leaderboard.firebaseio.com",
  projectId: "zimmer-leaderboard",
  storageBucket: "zimmer-leaderboard.appspot.com",
  messagingSenderId: "28098116350"
};
firebase.initializeApp(config);

export const firestore = firebase.firestore();

// For testing purposes - remove when going live
window.firebase = firebase;
