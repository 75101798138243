import React, { useState } from "react";
import ConsultantService from "./features/consultants/ConsultantService";
import styled from "styled-components";

export function ConsultantRow({
  initialName,
  initialTeam,
  initialScore,
  initialScores,
  id,
  handleDelete
}) {
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(initialName);
  const [team, setTeam] = useState(initialTeam);
  const [score, setScore] = useState(initialScore);

  // ////////////////SCORES///////////////////////

  const [armScore, setArmScore] = useState(
    initialScores !== {} ? initialScores.armageddon : 0
  );
  const [accScore, setAccScore] = useState(
    initialScores !== {} ? initialScores.accelerate : 0
  );
  const [madScore, setMadScore] = useState(
    initialScores !== {} ? initialScores.madamps : 0
  );

  //////////////////////TEAMS/////////////////////

  const [armageddon, setArmageddon] = useState(
    initialTeam.includes("armageddon")
  );
  const [carnage, setCarnage] = useState(initialTeam.includes("carnage"));
  const [accelerate, setAccelerate] = useState(
    initialTeam.includes("accelerate")
  );
  const [madamps, setMadAmps] = useState(initialTeam.includes("madamps"));

  const onUpdate = async () => {
    let teamArray = [];

    if (armageddon === true) {
      teamArray.push("armageddon");
    }

    if (accelerate === true) {
      teamArray.push("accelerate");
    }
    if (madamps === true) {
      teamArray.push("madamps");
    }

    setTeam(teamArray);

    await ConsultantService.updateConsultant(id, {
      consultant: name,
      team: teamArray,
      scores: {
        accelerate: parseInt(accScore),
        armageddon: parseInt(armScore),
        madamps: parseInt(madScore)
      },
      accelerate: parseInt(accScore),
      armageddon: parseInt(armScore),
      madamps: parseInt(madScore)
    });
    setEdit(false);
    // window.location.reload();
  };

  console.log(initialScores);

  return (
    <tr>
      {edit == false ? (
        <>
          <td>{name}</td>
          <td>
            {team.map(x => (
              <Label>{x}</Label>
            ))}
          </td>
          <td>
            <ScoreDisplay
              visible={accelerate}
              label="accelerate"
              score={accScore}
            />

            <ScoreDisplay
              visible={armageddon}
              label="armageddon"
              score={armScore}
            />
            <ScoreDisplay visible={madamps} label="madamps" score={madScore} />
          </td>
          <td>
            <button type="button" onClick={() => setEdit(true)}>
              edit
            </button>
          </td>
        </>
      ) : (
        <>
          <td>
            <input
              type="text"
              value={name}
              onChange={event => {
                setName(event.target.value);
              }}
            />
          </td>
          <td>
            <CheckContainer>
              <Checkbox
                name="accelerate"
                stateValue={accelerate}
                update={setAccelerate}
                label="Accelerate"
              />
              <Checkbox
                name="armageddon"
                stateValue={armageddon}
                update={setArmageddon}
                label="Armageddon"
              />

              <Checkbox
                name="madamps"
                stateValue={madamps}
                update={setMadAmps}
                label="Mad Amps"
              />
            </CheckContainer>
          </td>
          <td>
            <ScoreInput
              label="Accelerate"
              visible={accelerate}
              update={setAccScore}
              score={accScore}
            />
            <ScoreInput
              label="Armageddon"
              visible={armageddon}
              update={setArmScore}
              score={armScore}
            />

            <ScoreInput
              label="Mad Amps"
              visible={madamps}
              update={setMadScore}
              score={madScore}
            />
          </td>
          <td>
            <button
              className="sav"
              onClick={() => {
                onUpdate(id);
                // setEdit(false);
              }}
            >
              Save
            </button>

            <button
              className="del"
              onClick={() => {
                ConsultantService.deleteConsultant(id);
                setEdit(false);
                handleDelete(id);
              }}
            >
              del
            </button>
          </td>
        </>
      )}
    </tr>
  );
}

const Checkbox = ({ name, label, stateValue, update }) => (
  <label>
    <input
      value={name}
      type="checkbox"
      name={name}
      id={name}
      checked={stateValue}
      onChange={() => {
        update(!stateValue);
      }}
    />
    {label}
  </label>
);

const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;

  input {
    /* padding-right: 10px; */
    margin: 5px;
    box-shadow: none;
  }
`;

const ScoreInput = ({ visible, score, update, label }) =>
  visible && (
    <ScoreLabel>
      <input
        type="number"
        value={score}
        onChange={event => {
          update(event.target.value);
        }}
      />
      {`   ${label}`}
    </ScoreLabel>
  );

const ScoreDisplay = ({ visible, score, label }) =>
  visible && (
    <Label>
      {label} : {score}
    </Label>
  );

const Label = styled.label`
  display: block;
`;

const ScoreLabel = styled.label`
  display: block;
  padding: 3px;
  border-bottom: 1px solid #80808061;
  input {
    padding: 5px;
    width: 80px;
    box-shadow: none;
  }
`;
